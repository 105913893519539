import React, { Component } from 'react'
import {
  Button,
  Input,
  Page,
  Spacer,
  Box,
  ShippingDdtModal,
  List,
} from 'components'
import { navigate, getLocationState } from 'shared/router'
import { CustomShippingNotice, ShippingDdt } from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import PackingLists from 'api/PackingLists'
import DdtApi from 'api/Ddt'
import PackingListRow from "../../components/PackingListRow";

interface State {
  notices: CustomShippingNotice[]
  shipmentCodes: string[]
  filter: string
  loading: boolean
  disableCheckboxes: { packingListId : number, enabled : boolean}[]
  query: string
  isAllChecked: boolean
  parcelToModal?: string
  isModalOpen: boolean
  isCreatingDdt: boolean
  shippingddt?: ShippingDdt
  isAllCheckButtonEnable : boolean
  selectedMadeFor?: string
}

let checkedList : any = []

const choicesToSeparateConst : string[] = ['japan']

let disabledBoxesPrev : any = []

export default class DdtCreate extends Component<{}, State> {
  state: State = {
    notices: [],
    shipmentCodes: [],
    filter: '',
    loading: true,
    disableCheckboxes: [{packingListId : 0 , enabled : false }],
    query: '',
    isAllChecked: false,
    parcelToModal: undefined,
    isModalOpen: false,
    isCreatingDdt: false,
    isAllCheckButtonEnable:false
  }

  componentDidMount() {
    const locationState = getLocationState(this.props)

    PackingLists.searchCounters({
      shippingCodes: [],
      parcelCodes: [],
      parcelStates: [],
      originPlaces: [],
      destinationPlaces: [locationState.destination?.code],
      packingListStates: ['WAIT_FOR_DDT'],
      operationId: '',
      parcelsData: false
    })
      .then((data) => {
        const disCheckboxes = [...(data ?? []).map((n) => { return { packingListId : n.packingListId , enabled : false}})]
        if(disCheckboxes && disCheckboxes.length > 0){
          disabledBoxesPrev.forEach((previous) => {
            let foundDisabled = disCheckboxes.find(b => b.packingListId === previous)
            foundDisabled && (foundDisabled.enabled = true)
          })
          this.setState({disableCheckboxes : disCheckboxes})
        }
        this.setState({ loading: false, notices: data ?? [], selectedMadeFor: undefined,
          isAllCheckButtonEnable : (data ?? []).map(n => choicesToSeparateConst.includes(n?.choice?.code ?? ''))
            .every( (val, i, arr) => val === arr[0] ) })
        //this.handleAllCheck(false, data?.map((n) => n.packingListId) ?? [])
      })
      .catch((err) => {
        this.setState({ loading: false, notices: [] })
        showToast({
          title: __(T.error.error),
          description: err ?? 'Generic error',
          status: 'error',
        })
        this.navigateBack()
      })
  }

  /**
   *The function calls the DdtApi getting octet-stream which represents the pdf file.
   *A new HTML element is created to achieve this functionality specifying the tagName 'a',
   *the link is created and it's attached to the body, clicked automatically to download the byteStream and detached.*/
  downloadPdfDraft = async (shippingddt: ShippingDdt | undefined) => {
    try {
      const response = await DdtApi.exportPdfDraft(shippingddt!.id)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.download = shippingddt?.ddt?.concat('.pdf') ?? shippingddt?.idDdt?.concat('.pdf') ?? 'DDT.pdf'
      link.href = window.URL.createObjectURL(response)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? __(T.error.error),
        status: 'error',
      })
    }
  }

  createDdt = async (shipmentCodes: string[], destinationPlace: string) => {
    this.setState({loading: true, isModalOpen: true, isCreatingDdt: true })
    await DdtApi.create({ shipmentCodes, destinationPlace })
      .then(async (createResponse) => {
        await this.downloadPdfDraft(createResponse)
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.operation_success),
          status: 'success',
        })
        checkedList = []
        disabledBoxesPrev = []
        navigate('/shippingddt/list')
      })
      .catch((error) => {
        showToast({
          title: __(T.error.error),
          description: error?.message ?? 'Generic error',
          status: 'error',
        })
      })
        .finally(() => this.setState({loading: false, isModalOpen: false, isCreatingDdt: false }))
  }

  navigateBack = () => {
    checkedList = []
    disabledBoxesPrev = []
    navigate('/shippingddt')
  }

  setAllCheckBox = (valueToSet : boolean) => {
    const disCheckboxes = [...(this.state.notices ?? []).map((n) => { return { packingListId : n.packingListId , enabled : valueToSet}})]
    if(disCheckboxes && disCheckboxes.length > 0){
      this.setState({disableCheckboxes : disCheckboxes})
    }
  }

  disableTheseNoticesByChoice = (packingListIdEnabled : number, selectedMadeFor?: string, choicesToSeparate = choicesToSeparateConst) => {
    let disCheckboxes = this.state.disableCheckboxes

    const choiceCode = this.state.notices.find(n => n.packingListId === packingListIdEnabled)?.choice?.code ?? ''
    for(var cb of disCheckboxes){
      const cc = this.state.notices.find(n => n.packingListId === cb.packingListId)?.choice?.code ?? ''
      const cbMadeFor = this.state.notices.find(n => n.packingListId === cb.packingListId)?.madeFor ?? 'Undefined'
      if(choicesToSeparate.includes(choiceCode)){
        if(!choicesToSeparate.includes(cc)){
          cb.enabled = true
          !disabledBoxesPrev.includes(cb.packingListId) && disabledBoxesPrev.push(cb.packingListId)
        }else
        {
          cb.enabled = false
        }
      }else{
        if(choicesToSeparate.includes(cc)){
          cb.enabled = true
          !disabledBoxesPrev.includes(cb.packingListId) && disabledBoxesPrev.push(cb.packingListId)
        }else
        {
          cb.enabled = false
        }
      }
      if (cbMadeFor !== selectedMadeFor){
        cb.enabled = true
        !disabledBoxesPrev.includes(cb.packingListId) && disabledBoxesPrev.push(cb.packingListId)
      }
    }

    this.setState({disableCheckboxes : disCheckboxes})
  }

  handleChange = (event: any) => {
    let { notices, selectedMadeFor } = this.state
    let isAllChecked = false
    disabledBoxesPrev = disabledBoxesPrev.filter((e) => {return e != event.target.value.toString()})
    const madeFor = notices.find(n => n.packingListId === event.target.value)?.madeFor
    if (!selectedMadeFor) {
      selectedMadeFor = madeFor ?? 'Undefined'
    }
    if (event.target.checked) {
      this.disableTheseNoticesByChoice(event.target.value, selectedMadeFor)
      checkedList.push(event.target.value)
      isAllChecked = checkedList.length === notices.length
    } else {
      const idx = checkedList.indexOf(event.target.value)
      checkedList.splice(idx, 1)
      if(checkedList.length === 0){
        this.setAllCheckBox(false)
        selectedMadeFor = undefined
      }
    }
    this.setState({ isAllChecked, selectedMadeFor: selectedMadeFor})
  }

  handleAllCheck = (checked: boolean, data: number[]) => {
    this.setAllCheckBox(true)
    const { notices } = this.state
    const madeFor = notices.map(n => n.madeFor) ?? 'Undefined'
    if(madeFor.length > 1){
      showToast({
        title: __(T.error.ddt_with_different_madeFor),
        description: 'Cannot select all with DTT with different MadeFor',
        status: 'error',
      })
      this.setAllCheckBox(false)
      return
    }
    if (checked) {
      data.forEach((n) => {
        if (!checkedList.includes(n)) checkedList.push(n)
      })
    } else {
      data.forEach((n) => {
        const i = checkedList.indexOf(n)
        checkedList.splice(i, 1)
      })
    }
    this.setState({
      isAllChecked: checkedList.length === notices.length
    })
    this.setAllCheckBox(false)
  }

  onInputEnter = (input: string) => {
    this.setState({ query: input })
  }

  onModalClose = () => {
    this.setState({ isModalOpen: false, shippingddt: undefined })
  }

  filterResults = (result: CustomShippingNotice) => {
    const { query } = this.state
    return result.packingListId?.toString().includes(query.toLowerCase()) ||
    result.tom?.toString().includes(query.toLowerCase())
  }

  onRowClick = (shippingCode: string) => {
    this.setState({selectedMadeFor: undefined})
    navigate('/shippingddt/pl-row-detail/:shippingCode', {
      shippingCode: shippingCode
    })
  }

  sortingFunction = (a: CustomShippingNotice, b: CustomShippingNotice) => {
    return  b.packingListId - a.packingListId
  }

  render() {
    const { notices, loading, disableCheckboxes, isAllChecked, isModalOpen, shippingddt, shipmentCodes, isCreatingDdt } = this.state
    const locationState = getLocationState(this.props)
    const filtered = notices?.filter(this.filterResults).sort(this.sortingFunction)

    return (
      <Page
        loading={loading}
        title={`${__(T.titles.shipping_ddt) ?? 'Shipping DDT'} / ${__(T.misc.add_packing_list)}`}
        onBackPress={() => this.navigateBack()}
        header={{
          details: [
            {
              label: __(T.misc.ddt_destination),
              value: locationState.destination.description || locationState.destination.code,
            },
          ],
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.placeholder.search_shipment_pl)}
              onChange={(query) => this.setState({ query })}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Box flex />
            {this.state.isAllCheckButtonEnable && <Button
              loading={loading}
              title={isAllChecked ? __(T.misc.unselect_all) : __(T.misc.select_all)}
              variant={'secondary'}
              onClick={() =>
                this.handleAllCheck(
                  !isAllChecked,
                  filtered.map((n) => n.packingListId)
                )
              }
            />}
            <Spacer />
            <Button
              loading={loading}
              title={__(T.misc.confirm)}
              onClick={async () => {
                let shipmentCodes = filtered.filter((n) => checkedList.includes(n.packingListId)).map((n) => n.code!)
                this.setState({ shipmentCodes })
                if (shipmentCodes.length === 0) {
                  showToast({
                    title: __(T.error.error),
                    description: __(T.messages.select_on_or_more_parcels),
                    status: 'error',
                  })
                } else {
                  let submittedNotices = notices.filter((n) => checkedList.includes(n.packingListId))
                  let ddtDestination = locationState.destination
                  let ddtOrigin = notices[0].originPlace
                  let shippingddt: ShippingDdt = {
                    id: '',
                    ddt: '',
                    ddtDate: '',
                    year: '',
                    ddtState: 'DRAFT',
                    notices: submittedNotices,
                    destinationPlace: ddtDestination,
                    originPlace: ddtOrigin,
                    idDdt: '',
                  }
                  this.setState({ isModalOpen: true, shippingddt, isCreatingDdt: false })
                }
              }}
            />
          </Page.TopBar>
          <List
              loading={loading}
              title={`${__(T.titles.last_packing_lists)} (${filtered.length})`}
              renderItem={(notice) =>
                  <PackingListRow
                      disabledCheckBox={disableCheckboxes.find(n => n.packingListId === notice.packingListId)?.enabled}
                      shippingNotice={notice}
                      onRowClick={this.onRowClick}
                      showPLId={true}
                      enableCheckbox={true}
                      checkboxHandleChange={this.handleChange}
                      checkboxLabel={`${__(T.misc.select)}`}
                      isChecked={checkedList.includes(notice.packingListId)}
                      showPLNote={true}
                  />
              }
              data={filtered}
          />
        </Page.Content>
        { shippingddt && (
            <ShippingDdtModal
                isOpen={isModalOpen}
                data={shippingddt}
                loading={isCreatingDdt}
                loadingText={__(T.messages.waiting_ddt_creation)}
                onClose={this.onModalClose}
                onConfirm={() => this.createDdt(shipmentCodes, shippingddt?.destinationPlace.code)}/>
        )}
      </Page>
    )
  }
}
